.container {
  background-color: #B45B5B;
  outline: 1px solid #fff;
  outline-offset: -3px;
  color: #fff;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .content {
    display: none;
  }
}
