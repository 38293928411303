.container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.playerCounts {
  padding-right: 20px;
  font-family: monospace;
}

.description {
  padding-right: 50px;
  width: 100%;
  text-align: left;
}
