.container {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 999px;
  margin-right: 2px;
}

.currentTurn {
  animation: breathing 2s ease-out infinite normal;
}

.large {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

@keyframes breathing {
  0% {
    transform: scale(.9);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(.9);
  }
}
