.item {
  display: flex;
  padding-bottom: 5px;
  align-items: center;
  justify-content: space-between;
}

.coins {
  display: flex;
  padding-left: 10px;
}
