.items {
  background-color: hsla(15, 9%, 54%, 1);
  padding: 10px;
  max-height: 300px;
  overflow: auto;
}

.item {
  font-size: 12px;
  padding: 5px 0;
}
