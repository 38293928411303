.container {
  padding: 10px 0;
}

.info {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}
