.container {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

.name {
  padding-left: 10px;
}
