.container {
  display: block;
  width: 100%;
  padding: 15px 40px;
  border: 2px solid #fff;
  color: #fff;
  background-color: #25453D;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
}

.container:disabled {
  background-color: grey;
}
