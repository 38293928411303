.container {
  display: flex;
  justify-content: space-around;
}

.dice {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  color: #fff;
}
