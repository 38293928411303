.container {
  display: flex;
}

.board {
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .container {
    display: block;
  }

  .board {
    width: inherit;
  }
}
