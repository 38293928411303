.container {
  font-size: 80px;
  padding-bottom: 30px;
  letter-spacing: 4px;
  font-family: 'Luckiest Guy', cursive;
  color: #ea3c53;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.medium {
  font-size: 40px;
  padding-bottom: 20px;
  letter-spacing: 2px;
}
