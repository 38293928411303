.container {
  min-height: 100vh;
  animation: show 1s ease-out;
  background-color: hsla(0, 0%, 0%, 0.95);
  padding: 20px;
  color: #fff;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.title {
  font-size: 20vw;
  line-height: 20vw;
  color: #ea3c53;
  animation: title 4.5s ease-out;
  text-align: center;
  letter-spacing: 4px;
  font-family: 'Luckiest Guy', cursive;
  color: #ea3c53;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.player {
  width: 100%;
  text-align: center;
}

svg.revolver {
  fill: #fff;
  width: 20vw;
  height: 20vw;
}

.leftRevolver {
  animation: rotation-ccw 4s ease-out, revolver-sizing 6s ease-out;
}

.rightRevolver {
  animation: rotation-cw 4s ease-out, revolver-sizing 6s ease-out;
}

.roses {
  animation: roses 8s;
  height: 0;
  width: 0;
}

.currentTurn {
  padding-top: 20px;
  max-width: 200px;
  margin: 0 auto;
}

@keyframes roses {
  0% {
    height: 100vh;
    width: 100vw;
    opacity: 0.1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    display: block;
    opacity: 0.1;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes title {
  0% {
    font-size: 10vw;
    opacity: 0;
  }

  90% {
    font-size: 10vw;
    opacity: 0;
  }

  100% {
    font-size: 20vw;
    opacity: 1;
  }
}

@keyframes rotation-cw {
  0% {
    transform: rotate(-5000deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotation-ccw {
  0% {
    transform: rotate(5000deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes revolver-sizing {
  90% {
    width: 100%;
    height: 20vw;
  }

  100% {
    width: 20vw;
    height: 20vw;
  }
}
