.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 40px;
  letter-spacing: 4px;
  font-family: 'Luckiest Guy', cursive;
  color: #ea3c53;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.name {
  padding-top: 10px;
  font-size: 80px;
  color: #000;
}
