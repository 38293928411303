.container {
  padding: 20px 0;
}

.innerContainer {
  display: flex;
  justify-content: center;
}

.sum {
  background-color: #fff;
  color: #000;
  border-radius: 9999px;
  margin: 2px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
