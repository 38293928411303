.container {
  background-color: #583319;
  outline: 1px dashed #000;
  color: #fff;
  text-align: center;
  font-family: 'Luckiest Guy', cursive;
}

@media screen and (max-width: 768px) {
  .content {
    display: none;
  }
}
