.player {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.name {
  padding-left: 10px;
  font-size: 20px;
}
