.container {
  background-color: #B9AFAB;
  min-width: 250px;
  max-width: 300px;
  padding: 20px;
}

@media screen and (max-width: 700px) {
  .container {
    max-width: inherit;
  }
}
