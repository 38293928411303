h1, h2, h3, h4, h5, h6, h7, h8 {
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: normal;
}

input {
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: initial;
  border: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  text-align: left;
  display: block;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
