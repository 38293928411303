.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  width: inherit;
  margin: 5px;
}
