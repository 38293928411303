@import url(https://fonts.googleapis.com/css?family=Luckiest+Guy);
h1, h2, h3, h4, h5, h6, h7, h8 {
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: normal;
}

input {
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: initial;
  border: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  text-align: left;
  display: block;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #AB9F9B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Main_button__2ig8Z {
  margin-bottom: 10px;
}

.Button_container__-93ub {
  display: block;
  width: 100%;
  padding: 15px 40px;
  border: 2px solid #fff;
  color: #fff;
  background-color: #25453D;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
}

.Button_container__-93ub:disabled {
  background-color: grey;
}

.Logo_container__zSQMm {
  font-size: 80px;
  padding-bottom: 30px;
  letter-spacing: 4px;
  font-family: 'Luckiest Guy', cursive;
  color: #ea3c53;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.Logo_medium__dghNL {
  font-size: 40px;
  padding-bottom: 20px;
  letter-spacing: 2px;
}

.CenteredContainer_outer__3DnUJ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.CenteredContainer_inner__2z8Dh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Heading_container__1qLMt {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 20px;
  color: white;
}

.PlayersCountSelect_container__20Tn6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.PlayersCountSelect_item__2FPpD {
  width: inherit;
  margin: 5px;
}

.Players_input__14Ejm {
  margin-bottom: 20px;
}

.Input_container__3Zj6l {
  display: block;
  max-width: 300px;
  width: 100%;
  padding: 15px;
  min-width: 200px;
  font-size: 24px;
}

.PlayerNamesMissingError_container__1mAV7 {
  color: red;
  padding: 20px;
}

.Item_container__1yQV5 {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.Item_playerCounts__OGkZO {
  padding-right: 20px;
  font-family: monospace;
}

.Item_description__q_WSi {
  padding-right: 50px;
  width: 100%;
  text-align: left;
}

.NewPlayer_input__LO6ll {
  margin-bottom: 20px;
}

.PlayerNamesMissingError_container__3L10H {
  color: red;
  padding: 20px;
}

.Avatar_container__1l2qe {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 999px;
  margin-right: 2px;
}

.Avatar_currentTurn__1X-mp {
  -webkit-animation: Avatar_breathing__1QOeE 2s ease-out infinite normal;
          animation: Avatar_breathing__1QOeE 2s ease-out infinite normal;
}

.Avatar_large__69R36 {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

@-webkit-keyframes Avatar_breathing__1QOeE {
  0% {
    -webkit-transform: scale(.9);
            transform: scale(.9);
  }

  50% {
    -webkit-transform: scale(2);
            transform: scale(2);
  }

  100% {
    -webkit-transform: scale(.9);
            transform: scale(.9);
  }
}

@keyframes Avatar_breathing__1QOeE {
  0% {
    -webkit-transform: scale(.9);
            transform: scale(.9);
  }

  50% {
    -webkit-transform: scale(2);
            transform: scale(2);
  }

  100% {
    -webkit-transform: scale(.9);
            transform: scale(.9);
  }
}

.PlayerNameAndAvatar_container__3LOIw {
  display: flex;
}

.PlayerNameAndAvatar_center__3cFe6 {
  justify-content: center;
  align-items: center;
}

.PlayerNameAndAvatar_name__2ODmQ {
  padding-left: 10px;
}

.Board_container__1CaOO {
  position: relative;
  width: 100%;
}

.Space_container__2CDNt {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.base_container__11BKw {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Wagon_container__1wTea {
  background-color: #62566D;
}

.Wagon_icon__2vJLo {
  width: 20px;
  height: 20px;
  fill: hsla(0, 0%, 100%, 0.5);
}

.ElBacko_container__3jcG8 {
  background-color: #B45B5B;
  outline: 1px solid #fff;
  outline-offset: -3px;
  color: #fff;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .ElBacko_content__2KCW2 {
    display: none;
  }
}

.Normal_container__sksvX {
  background-color: #25453D;
}

.Alamo_container__2jTuI {
  background-color: #57432A;
}

.BrownTile_container__3RRqR {
  background-color: #583319;
  outline: 1px dashed #000;
  color: #fff;
  text-align: center;
  font-family: 'Luckiest Guy', cursive;
}

@media screen and (max-width: 768px) {
  .BrownTile_content__3j_NM {
    display: none;
  }
}

.Player_container__1zHX5 {
  position: absolute;
  -webkit-transition: all .1s linear, -webkit-transform .5s ease;
  transition: all .1s linear, -webkit-transform .5s ease;
  transition: all .1s linear, transform .5s ease;
  transition: all .1s linear, transform .5s ease, -webkit-transform .5s ease;
}

.Title_container__165A0 {
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
}

.Coin_container__3v63p {
  margin-left: 5px;
  border-radius: 999px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.Bank_coins__u3Dj6 {
  display: flex;
  padding-bottom: 20px;
}

.PlayersList_item__19NxC {
  display: flex;
  padding-bottom: 5px;
  align-items: center;
  justify-content: space-between;
}

.PlayersList_coins__3iaKa {
  display: flex;
  padding-left: 10px;
}

.Responses_container__1_Smu {
  padding: 10px 0;
}

.Responses_info__107D0 {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}

.Response_container__3VpVQ {
  margin-bottom: 5px;
}

.Waiting_container__1lxQu {
  padding: 10px 0 30px;
}

.Dices_container__3TB8_ {
  display: flex;
  justify-content: space-around;
}

.Dices_dice__2AEUS {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  color: #fff;
}

.CurrentTurn_player__lGmIS {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.CurrentTurn_name__1mrdT {
  padding-left: 10px;
  font-size: 20px;
}

.Log_items__PSUjY {
  background-color: hsla(15, 9%, 54%, 1);
  padding: 10px;
  max-height: 300px;
  overflow: auto;
}

.Log_item__1UlqH {
  font-size: 12px;
  padding: 5px 0;
}

.Meta_container__1Uh9q {
  background-color: #B9AFAB;
  min-width: 250px;
  max-width: 300px;
  padding: 20px;
}

@media screen and (max-width: 700px) {
  .Meta_container__1Uh9q {
    max-width: inherit;
  }
}

.Winner_container__1H3xA {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 40px;
  letter-spacing: 4px;
  font-family: 'Luckiest Guy', cursive;
  color: #ea3c53;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.Winner_name__2EhtN {
  padding-top: 10px;
  font-size: 80px;
  color: #000;
}

.CurrentTurn_container__1Oq2W {
  max-width: 200px;
  margin: 0 auto;
}

.Score_container__1xnrb {
  padding: 20px 0;
}

.Score_innerContainer__3caGn {
  display: flex;
  justify-content: center;
}

.Score_sum__2kiHd {
  background-color: #fff;
  color: #000;
  border-radius: 9999px;
  margin: 2px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Duel_container__3m8x5 {
  min-height: 100vh;
  -webkit-animation: Duel_show__wT2Sg 1s ease-out;
          animation: Duel_show__wT2Sg 1s ease-out;
  background-color: hsla(0, 0%, 0%, 0.95);
  padding: 20px;
  color: #fff;
}

.Duel_innerContainer__2D1Sl {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.Duel_title__3uBn8 {
  font-size: 20vw;
  line-height: 20vw;
  color: #ea3c53;
  -webkit-animation: Duel_title__3uBn8 4.5s ease-out;
          animation: Duel_title__3uBn8 4.5s ease-out;
  text-align: center;
  letter-spacing: 4px;
  font-family: 'Luckiest Guy', cursive;
  color: #ea3c53;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.Duel_player__2uYZw {
  width: 100%;
  text-align: center;
}

svg.Duel_revolver__1Yvgv {
  fill: #fff;
  width: 20vw;
  height: 20vw;
}

.Duel_leftRevolver__48tXO {
  -webkit-animation: Duel_rotation-ccw__2qupq 4s ease-out, Duel_revolver-sizing__2Yep4 6s ease-out;
          animation: Duel_rotation-ccw__2qupq 4s ease-out, Duel_revolver-sizing__2Yep4 6s ease-out;
}

.Duel_rightRevolver__1IRUt {
  -webkit-animation: Duel_rotation-cw__3ybMJ 4s ease-out, Duel_revolver-sizing__2Yep4 6s ease-out;
          animation: Duel_rotation-cw__3ybMJ 4s ease-out, Duel_revolver-sizing__2Yep4 6s ease-out;
}

.Duel_roses__174UO {
  -webkit-animation: Duel_roses__174UO 8s;
          animation: Duel_roses__174UO 8s;
  height: 0;
  width: 0;
}

.Duel_currentTurn__3EI_Q {
  padding-top: 20px;
  max-width: 200px;
  margin: 0 auto;
}

@-webkit-keyframes Duel_roses__174UO {
  0% {
    height: 100vh;
    width: 100vw;
    opacity: 0.1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    display: block;
    opacity: 0.1;
  }
}

@keyframes Duel_roses__174UO {
  0% {
    height: 100vh;
    width: 100vw;
    opacity: 0.1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    display: block;
    opacity: 0.1;
  }
}

@-webkit-keyframes Duel_show__wT2Sg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Duel_show__wT2Sg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes Duel_title__3uBn8 {
  0% {
    font-size: 10vw;
    opacity: 0;
  }

  90% {
    font-size: 10vw;
    opacity: 0;
  }

  100% {
    font-size: 20vw;
    opacity: 1;
  }
}

@keyframes Duel_title__3uBn8 {
  0% {
    font-size: 10vw;
    opacity: 0;
  }

  90% {
    font-size: 10vw;
    opacity: 0;
  }

  100% {
    font-size: 20vw;
    opacity: 1;
  }
}

@-webkit-keyframes Duel_rotation-cw__3ybMJ {
  0% {
    -webkit-transform: rotate(-5000deg);
            transform: rotate(-5000deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes Duel_rotation-cw__3ybMJ {
  0% {
    -webkit-transform: rotate(-5000deg);
            transform: rotate(-5000deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes Duel_rotation-ccw__2qupq {
  0% {
    -webkit-transform: rotate(5000deg);
            transform: rotate(5000deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes Duel_rotation-ccw__2qupq {
  0% {
    -webkit-transform: rotate(5000deg);
            transform: rotate(5000deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes Duel_revolver-sizing__2Yep4 {
  90% {
    width: 100%;
    height: 20vw;
  }

  100% {
    width: 20vw;
    height: 20vw;
  }
}

@keyframes Duel_revolver-sizing__2Yep4 {
  90% {
    width: 100%;
    height: 20vw;
  }

  100% {
    width: 20vw;
    height: 20vw;
  }
}

.Game_container__2ed6m {
  display: flex;
}

.Game_board__2l3t5 {
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .Game_container__2ed6m {
    display: block;
  }

  .Game_board__2l3t5 {
    width: inherit;
  }
}

